.receive-qr {
    margin: 24px;
    background: #f2f2f2;
    padding: 12px;
    text-align: center;
    border-radius: 15px 15px 0 0;
    /*min-height: 40vh;*/
    color: #666666;
    font-weight: 600;
    max-height: 500px;
}


.qr-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}

.qr-1 div {
    padding: 12px;
    margin: 24px 12px;
    border: 4px solid #d7d8da;
    border-radius: 15px;
}

.qr-btn {
    padding: 6px 24px;
    margin: 0px 24px;
    background: #f2f2f2;
    text-align: center;
    border-radius: 0 0  15px 15px;
}

.input-addr{
    --background: var(--ion-color-light) !important;
    --border-radius: 10px;
    background: var(--ion-color-light) !important;
    border-radius: 15px;
    padding: 12px 12px 0;
    font-size: 20px;
    margin: 12px 0;
}

.input-amt{
    font-size: 28px;
    font-family: 'Abel-Regular', sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
}
.balance-span{
    float:right;
    text-align: right;
    font-weight: 600;
    font-family: 'Abel-Regular', sans-serif;
    letter-spacing: 1px;
}
.btn-max{
    float: right;
    font-size: 12px;
    font-weight: 600;
    background: var(--ion-color-light);
    padding: 2px;
}
.input-d1{
    position: relative;
    width: 100%;
    border-bottom: 2px solid var(--ion-color-primary);
    margin: 12px 0;
}
.input-d2{
    position: absolute;
    right: 0;
    bottom: 0;
    color: var(--ion-color-primary);
    padding:6px 12px;
    border-left: 1px solid var(--ion-color-light);
}

.nft-info2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    overflow: hidden;
    padding: 24px;
}
.nft-box2{
    background: var(--ion-color-light);
}

.btn-bottom{
    position: fixed;
    width: 100%;
    bottom: 12px;
    z-index: 10002;
}
.balance-span2{
    font-weight: 600;
    font-family: 'Abel-Regular', sans-serif;
    letter-spacing: 1px;
}