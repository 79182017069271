.nft-list {
    position: relative;
    height: 100%;
    max-height: 48vw;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 15px;

    margin: 0 auto;
}

.nft-item {
    padding: 6px 6px;
    position: absolute;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: #4d4d4d;
    opacity: 0.8;
    color: #ffffff;
    font-weight: 600;
    border-radius: 5px 5px 0 0;
    font-size: 14px;
}

.nft-symbol {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
}

.nft-img {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    overflow: hidden;

}

.nft-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nft-info .img{
    position: relative;
    padding: 12px;
    display: flex;
    justify-content: center;
}

.nft-info .img img {
    min-height: 30vh;
    max-height: 45vh;
}

.nft-info .properties{
    border: 1px solid #d7d8da;
    border-radius: 5px;
    padding: 6px;
    font-weight: 600;
    box-shadow: 0 0 1px 1px #d7d8da;
}

.nft-info .properties div:first-child{
    color: #8c8c8c;
}

.nft-info .properties div:last-child{
    font-weight: 700;
    font-size: 16px;
}