body {
    background: linear-gradient(135deg, rgb(2, 0, 36) 0%, rgba(9,9,121,1) 35%, rgb(187, 103, 248) 100%);
}
.stuck{
    background: #000;
    opacity: 0.5;
}

.ion-page {
    /*max-width: 600px !important;*/
    /*max-height: 844px !important;*/
    /*left: 50% !important;;*/
    /*transform: translate(-50%,-20%) !important;;*/
    /*top: 20% !important;*/
    /*width: 100% !important;*/
    /*height: 100% !important;*/
}

.accounts-popover {
    --height: 90vh;
    --width: 80vw;
    --max-width: 300px;
    --background: #000;
}

.ndcr{
    --max-width: 500px;
    --max-height: 250px;
}


@media (min-width: 769px) {
    .page {
        position: absolute;
        width: 768px;
        height: 90vh;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }

    .page-inner {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .accounts-popover {
        --offset-x: calc(-(100vw - 768px) / 2);
        --offset-y: calc((100vh - 1580px) / 2);
        --max-width: 360px !important;
    }

    .ion-page {
        /*border-radius: 15px;*/
    }

}