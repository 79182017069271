.page-inner{
}

.header{
    position: relative;
    text-align: center;
    padding: 12px 0 24px;
}

.content{

}

.footer{

}

.avatar{
    --border-radius: 50%;
    border: 1px solid #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.avatar div{
    font-size: 10px;
    color: var(--ion-color-medium);
    font-weight: 600;
}

.avatar-item{
    text-align: center;
    align-content: center;
    justify-content: center;
    display: flex;
}

.avatar-token{
    --border-radius: 50%;
    border: 1px solid #f7f7f7;
    display: flex;
    justify-content: center;
    align-content: center;
}
.segment {
    border-bottom: 2px solid #f7f7f7;
}
.seg-btn {
    --indicator-height: 3px;
}
.lines{
    --border-color: #f7f7f7;
    --padding-top: 6px;
}

.flex-center{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}